$table-border-color: #9b9b9b59;
$ancillaries-row-height: 60vh;

.ancillaries-drawer .ant-drawer-body {
  position: relative;
  padding: 12px 24px !important;
}

/* PRIORITY BOARDING SECTION */
.priority-boarding-section {
  height: $ancillaries-row-height;
  overflow-y: scroll;
}

.priority-boarding-row,
.baggage-row {
  border-bottom: 1px solid $table-border-color;
  padding-bottom: 5px;
  padding-top: 5px;
}

.priority-boarding-row:first-child,
.baggage-row:first-child {
  border-top: 1px solid $table-border-color !important;
}

/* BAGGAGE SECTION */
.baggage-section {
  overflow-y: scroll;
}
.baggage-row {
  padding: 6px !important;
}
.baggage-price-col,
.priority-boarding-price {
  text-align: right;
}
.baggage-select {
  width: 100%;
}
