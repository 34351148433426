.option-container {
  height: 80px;

  .ant-typography-success.cancellation-title {
    color: var(--success-info-color) !important;
  }
}

.more-options {
  padding-top: 20px;
}

.cancellationDate_button {
  padding: 0;
}
.cancellationDate_spiner {
  padding-left: 16px !important;
}
