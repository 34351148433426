.fare-container {
  text-align: center;
  .fare-container_subtext {
    font-size: 12px;
  }
}

.car-solution-row {
  .provider-ribbon {
    background-color: transparent !important;
  }
}
