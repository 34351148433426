.custom-select-dropdown-width {
    width: fit-content !important;
    min-width: 24rem !important;

    #smartSearch_list {
        width: 35rem !important; //fix for smartSearchHotel, there are a lot results and its changing when scroll
    }
}

.custom-input-width {
    width: 100%;
}

.submit-column {
    display: flex;
    align-items: end;
}

.submit-column.mb {
    margin-left: 12px;
    margin-bottom: 12px;
}

.searchForm-skeleton {
    width: 100%;
    min-height: 127px;
}

.skeletonForm_row {
    margin-top: 40px;
    margin-bottom: 30px;
}

.moreInfoOption {
    color: #ababab;
    font-size: 12px;
}

.searchInput-hotels_top {
    padding-bottom: 6px;
}

.searchInput-hotels_top.filters {
    text-align: left;
}

.searchInput-hotels_top .tag-hotels {
    margin-right: 20px;
}

.searchInput_estimate {
    display: flex;
    align-content: center;
    align-items: baseline;
}

.searchInput_estimate.travellers-input_width {
    display: flex;
    align-content: center;
    align-items: baseline;
    width: 126px;
}

.searchInput-cars_top {
    padding-top: 6px;
}

.searchInput-cars-container {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    .withIcon {
        padding-left: 0 !important;
    }
}

.form-top-row {
    display: flex;
    align-items: baseline;
    padding-top: 6px;
}

.form-top-row.hotels {
    padding-bottom: 4px;
}

.form-top-row.cars {
    width: 100%;
    padding-bottom: 33px;
}

.form-top-row.cars.isEstimate {
    width: 100%;
    padding-bottom: 10px;
}

.travellers-input_width {
    width: 765px;
}

.dates-input_width {
    width: 270px;
}

.search-input_width {
    width: 360px;
}

.direction-input_width {
    width: 45px;
}

.tripType-wrapper {
    margin-right: 10px;
}

.search-inputs-wrapper {
    display: flex;
}

.sbt-search-inputs,
.sbt-search-row {
    display: flex;
}

.sbt-search-width {
    width: 765px;
}

.inputNumber-width {
    width: 126px;
}

.search-input-wrapper {
    display: flex;
}

.abt-search-input_width {
    width: 320px;
}

.abt-search-input_width_poi {
    width: 196px;
}

.abt-search-input_width_hotel {
    width: 215px;
}

.abt-search-input_width_distance {
    width: 80px;
}

.providers-input-width {
    width: 245px;
}
