.journey-info {
  .centered-col {
    align-self: center;
  }

  .centered-text {
    text-align: center;
  }

  .days-count sup {
    margin-top: 0px;
    right: -4px !important;
    font-size: 8px;
    width: 16px;
    padding: 0;
  }

  .paragraph-mb-0 {
    margin-bottom: 0;
  }

  .box-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .size-text-hour{
    font-size:18px
  }
}

.transport-info {
  .img-col {
    display: flex;
  }
}


