.client-messages-container {
  width: 100%;
  max-height: 55vh;
  min-height: 55vh;
  overflow-y: auto;

  .client-messages-body {
    .space-width {
      width: 100%;
    }

    .comment-container {
      position: relative;

      .sender {
        border-radius: 4px;
        background: var(--text-color-light);
        position: absolute;
        height: 100%;
        width: 100%;
      }

      .customer {
        opacity: 0.6;
      }
      .other {
        opacity: 0.2;
      }
    }

    .comment {
      padding: 4px 10px;
      .article-author {
        color: var(--text-color-dark) !important;
        font-weight: 500;
        word-break: break-all;
      }

      .ant-comment-content-author-time {
        color: var(--text-color-dark);
        opacity: 0.6;
      }

      &_paragraph {
        white-space: break-spaces;
      }

      &_button {
        align-self: flex-end;
      }
    }
  }

  .ant-comment-content-detail {
    display: flex;
    flex-direction: column;
  }
}
