.grey-background {
    background: #f8f8f8;
}

.space-witdh-100 {
    width: 100%;
}

.divider-transparent {
    border-color: transparent;
}

.clickable-effect {
    cursor: pointer;
}

.center-content {
    display: flex;
    justify-content: center;
}

.title-with-tooltip {
    span {
        font-weight: inherit;
    }
}

.booking-tool-results {
    overflow-y: scroll;
    height: 90vh;
}

// Margins
.mb-5 {
    margin-bottom: 5px;
}

.mb-10 {
    margin-bottom: 10px;
}

.my-10 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.trip-mode-selector-card {
    margin-top: 0px;
    margin-bottom: -25px;
}

.trip-mode-selector {
    background-color: #cdcdcd;
    width: fit-content;
    border-radius: 50px;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    margin: auto;
}
