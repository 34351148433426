.iframe-modal-common {
  .ant-modal-header {
    display: none;
  }
  
  .ant-modal-body {
    height: 100%;
    width: 100%;
    padding: 0;
  }
  
  .ant-modal-footer {
    display: none;
  }
}


.iframe-modal {
  width: 100vw !important;
  height: 100vh !important;

  .ant-modal-content {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100vw;
    height: 100vh;

    @extend .iframe-modal-common
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

/* travellers iFrame */
.iframe-modal-fitContent {
  .ant-modal-content {
    position: fixed;
    height: 80vh;
    width: 530px;
    
   @extend .iframe-modal-common
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
