.detail_row {
  background-color: #f2f2f3;
  margin-bottom: 10px;
  .detail-date_col {
    padding-left: 9px;
  }
}

.segment_row {
  margin-bottom: 10px;
  .departure_col,
  .destination_col {
    display: flex;
    flex-direction: column;
  }
  .separation_col {
    position: relative;

    .separation_div {
      position: absolute;
      width: 80%;
      top: 50%;
      border: 1px solid #afadad;
    }
  }
}
