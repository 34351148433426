.transport-fares-block {
    margin: 30px 10px 10px 10px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    border-color: lightgrey;
    padding: 20px 20px 20px 20px;
}

.button-rigth {
    float: right;
}
