.hide-close-btn .ant-modal-close {
  display: none;
}

.iframe-modal {
  width: 50%;
}

.no-mask-drawer{
  width: 50% !important;
}