.voucher-label {
  max-width: 90%;
}

.item-pending {
  border-color: transparent;

  svg {
    $svg-size: 1.5em;
    width: $svg-size;
    height: $svg-size;
  }
}

.custom-class-container {
  .custom-type {
    color: inherit !important;
  }
}
