.car-journey-info-container {
  .journey-section-title {
    margin-bottom: 5px;
  }

  .journey-text-block {
    display: block;
  }

  .provider-logo {
    padding-top: 10px;
  }
}

.car-journey-info-container {
  .provider-ribbon {
    background-color: transparent !important;
  }
}
