.ancillaries-price-container {
  position: absolute;
  bottom: 0;
  right: 24px;
}

.ancillaries-price {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;

  span {
    margin-left: 5px;
  }

  h4 {
    margin-right: 5px;
  }
}
