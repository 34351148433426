.hotelImage-list {
  display: flex;
  width: 100%;

  div {
    margin-top: 4px;
    margin-right: 20px;
  }
  .hotelImage-list_container{
    position: relative;
    cursor: pointer;
  }
  .hotelImage-list_isImgSelected	{
    position: absolute;
    top: -9px;
    z-index: 1000;
    left: -4px;
    background-color: rgba(211, 211, 211, 0.886);
  
  }
}
