.custom-car-dates-container {
  width: 350px;
}

.custom-car-city-container {
  width: 350px;
}
.serachInput_top {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;

  &.pt {
    padding-top: 12px;
  }
}

.form-col {
  padding: 0;
}
.form-button {
  border: none !important;
  padding: 0 2px !important;
}
.form-button:disabled {
  background: none !important;
  padding: 0 2px;
}

@media (max-width: 1133px) {
  .custom-car-driver-input {
    width: 280px !important;
  }
  .custom-car-city-input {
    width: 280px !important;
  }
  .custom-car-dates-container {
    width: 310px !important;
  }
}
