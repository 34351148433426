.searchForm-container {
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  background: white;
}

.content-col {
  margin-bottom: 12px;
}
.content-col.space {
  margin-left: 20px;
}

.sideBar-container {
  height: 68vh;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 12px;
  position: sticky;
  top: 0;
  margin-top: 12px;
}
