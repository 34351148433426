.multi-trip-icons {
  display: flex;
  font-size: 16px;
  padding-top: 10px;

  .minus-icon {
    margin-right: 10px;
  }
}

.no-right-border-radius {
  .ant-select-selector {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}

.no-left-border-radius {
  .ant-select-selector {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.transport-journey-btn {
  border-radius: 0px !important;
  background: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

.transport-journey-btn:hover {
  opacity: 0.8;
  border-color: var(--primary-color) !important;
}
