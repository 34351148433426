.custom-image-col {
  display: flex;
  overflow: hidden;

  .ant-image {
    height: 70px;
  }

  img {
    flex: none;
  }
}
