.best-solution-features {
  max-width: 80%;
}

.regime-text {
  color: var(--primary-color);
  font-size: 12px;
}

.ant-typography-success.bestOption-cancelation-title {
  color: var(--success-info-color) !important;
}
