.searchForm-container.sbt{
  padding-bottom: 6px;
}
.tabsCategory_col{
  background-color: #e5e5e5
}


.form-midlle-div {
  display: flex; align-items: baseline;
} 

.content-col{
  margin-bottom: 12px;
}
.content-col.space{
margin-left: 20px;
}