$fares-row-height: 30vh;
$fares-row-height-s: $fares-row-height - 10;

.fares-container {
    .fare_alert {
        margin-bottom: 12px;
    }
    .fares-conditions-row {
        height: $fares-row-height;
        overflow: hidden;

        .scrollable-container {
            height: calc($fares-row-height / 1.5);
            overflow: scroll;
        }

        @media (max-height: 820px) {
            height: $fares-row-height + 5;

            .scrollable-container {
                height: $fares-row-height-s - 5;
            }
        }
    }
}
