$padding-size: 20px;
$boder-color: #d7d7d7;
$margin-size: 6px;

.transport-solutions {
    .transport-solution {
        .transport-journey:first-child {
            align-self: center;
        }

        .journey-border {
            padding-left: $padding-size;
            padding-bottom: calc($padding-size / 2);

            &:first-child {
                padding-top: $padding-size;
            }

            &:not(:last-child) {
                border-bottom: $boder-color 1px solid;
            }

            &:last-child {
                padding-bottom: $padding-size;
            }
        }

        .transport-buy-button {
            border-left: 1px solid $boder-color;
            padding-top: $padding-size;
            padding-bottom: $padding-size;
        }
    }
    .lastTicketDate {
        margin-left: $margin-size;
    }
}
