.nav-wrapper {
  display: flex;
}

.header-menu {
  background-color: #fff;

  .no-pointer-events {
    pointer-events: none;
  }

  .logo {
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-button {
    box-shadow: 0 0px 0 rgb(0 0 0 / 0%);
    padding: 5px;
    position: absolute;
    top: 1.5%;

    svg {
      height: 1.5em;
      width: 1.5em;
    }

    &:hover {
      color: var(--primary-color);
    }
  }

  .help-modal-button {
    left: 95%;
    border: none;
  }

  .logout-button {
    left: 97%;
    border: none;
  }

  .old-discovery-redirect {
    position: absolute;
    right: 1%;
    top: 0.5%;
  }

  .trip-number-item {
    align-self: flex-end;
  }

  .basket-button {
    position: absolute;
    top: 0.8%;
    left: 82%;
    margin-right: 30px;
  }
}

.nav-top-div {
  display: flex;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  justify-content: end;
  height: 100%;
  align-items: center;

  .basket-button {
    margin-right: 15px;
  }

  .logout-div {
    margin-right: 30px;

    .header-button.logout-button {
      border: none !important;
      padding: 4px 12px;
    }
  }

  .help-div {
    .header-button.help-modal-button {
      border: none !important;
      padding: 4px 12px;
    }
  }
}

.header-menu_skeleton {
  min-height: 45px;
}

.product-info-modal {
  .product-info {
    white-space: break-spaces;
    text-align: justify;
  }
}