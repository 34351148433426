.paymentInfo-price_col {
  text-align: right;
  padding-right: 10px;
}
.paymentInfo_row {
  margin-top: 12px;
  .paymentInfo-price_col {
    .price-container {
      text-align: right !important;
    }
  }
}
.paymentInfo-tld_row {
  text-align: right;
  margin-top: 12px;
}
